<template>
  <div id="contact-page" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <div class="page-title-area bg-image padding-50-pr bg-16" style="background-color: #31bfd5">
      <div class="container">
        <div class="page-title-content">
          <h2>PeaceJam Around The World</h2>
        </div>
      </div>
    </div>

    <section class="events-section ptb-50">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="contact-content">
              <p>
                PeaceJam Affiliates and Chapters bring the power of the PeaceJam Program to their area of the world,
                supporting local PeaceJam programming, service-learning projects, and youth events with our Affiliates
                earning the privilege of hosting an annual PeaceJam Conference with a Nobel Peace Laureate.
                Contact <a href="mailto:programs@peacejam.org">programs@peacejam.org</a> to start a Chapter in your community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <div id="mapHomeSection">
      <div style="height: 703px" class="loading" v-if="locations_data === undefined && !locations_error">
        <Loading/>
      </div>
<!--      <MapHomeSection v-if="locations_data" :locations="locations_data.data" :isContactPage="true"/>-->

      <LocationCards v-if="locations_data" :locations="locations_data.data"/>
    </div>

    <section class="contact-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="contact-wrap">
              <div id="constant_contact_inline_form" hidden>

                <div class="ctct-inline-form" data-form-id="38c9d8d3-b04e-49bf-a3f8-0e4c2570f8cf"></div>
              </div>
              <div class="contact-form">
                <div class="section-title">
                  <h2>Drop Us A Message For Any Query</h2>
                </div>

                <form id="contactForm" @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="contact.firstName"
                                id="firstName"
                                name="firstName"
                                class="form-control"
                                placeholder="First Name"
                                :class="{ 'is-invalid': submitted && $v.contact.firstName.$error }"
                        />
                        <div v-if="submitted && $v.contact.firstName.$error" class="help-block with-errors">
                          <span v-if="!$v.contact.firstName.required">First name is required</span>
                          <span v-if="!$v.contact.firstName.maxLength">Message max length is 50</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="contact.lastName"
                                id="lastName"
                                name="lastName"
                                class="form-control"
                                placeholder="Last Name"
                                :class="{ 'is-invalid': submitted && $v.contact.lastName.$error }"
                        />
                        <div v-if="submitted && $v.contact.lastName.$error" class="help-block with-errors">
                          <span v-if="!$v.contact.lastName.required">Last name is required</span>
                          <span v-if="!$v.contact.lastName.maxLength">Message max length is 50</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="contact.email"
                                id="email"
                                name="email"
                                class="form-control"
                                placeholder="Email"
                                :class="{ 'is-invalid': submitted && $v.contact.email.$error }"
                        />
                        <div v-if="submitted && $v.contact.email.$error" class="help-block with-errors">
                          <span v-if="!$v.contact.email.required">Email is required</span>
                          <span v-if="!$v.contact.email.email">Email is invalid</span>
                          <span v-if="!$v.contact.email.maxLength">Email max length is 80</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="contact.location"
                                id="location"
                                name="location"
                                class="form-control"
                                placeholder="Location (State, Country)"
                                :class="{ 'is-invalid': submitted && $v.contact.location.$error }"
                        />
                        <div v-if="submitted && $v.contact.location.$error" class="help-block with-errors">
                          <span v-if="!$v.contact.location.maxLength">Message max length is 255</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <textarea
                                name="message"
                                class="form-control hight-unset"
                                id="message"
                                cols="30"
                                rows="5"
                                placeholder="Your Message"
                                v-model="contact.message"
                                :class="{ 'is-invalid': submitted && $v.contact.message.$error }"
                        >
                        </textarea>
                        <div v-if="submitted && !$v.contact.message.required" class="help-block with-errors">Message is
                          required
                        </div>
                        <div v-if="submitted && $v.contact.message.$error" class="help-block with-errors">
                          <span v-if="!$v.contact.message.required">Message is required</span>
                          <span v-if="!$v.contact.message.maxLength">Message max length is 255</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex flex-column align-items-start text-left newsletter">
                      <div class="form-group">
                        <label class="mb-3">Are you on of the following?</label>

                        <ul>
                          <li v-for="(newsletter, index) in globalNewsletters" :key="newsletter.id">
                            <input
                            :id="index"
                            type="checkbox"
                            :value="newsletter.id"
                            v-model="contact.listMemberships">
                            <label :for="index">{{newsletter.name}}</label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex flex-column align-items-start text-left newsletter">
                      <div class="form-group">
                        <label class="mb-3">Would you like to learn more about our <a href="https://www.billionacts.org/">Billion Acts  </a> Initiative?</label>
                        <ul>
                          <input type="radio" id="yes" value="true" v-model="isBillionActsNewsletterSelected">
                          <label for="yes">Yes</label>
                          <br>
                          <input type="radio" id="no" value="false" v-model="isBillionActsNewsletterSelected">
                          <label for="no">No</label>
                          <br>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12 d-flex flex-column align-items-start text-left newsletter">
                       <div class="form-group">
                            <input
                            id="peace_jam_news"
                            type="checkbox"
                            :value="peaceJamGeneralNewsletter.id"
                            v-model="contact.listMemberships">
                          <label for="peace_jam_news">I'd like to stay informed of PeaceJam news and events</label>
                      </div>
                    </div>
                    <div class="col-12 form-group">
                      <div class="help-block with-errors" v-if="newsletterError">Newsletter is required</div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <button class="default-btn page-btn">
                        Send Message
                      </button>
                      <div id="msgSubmit" class="h3 text-center hidden"></div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>


          <div class="col-lg-4">
            <div>
              <div class="quick-contact">
                <h3>Quick Contact </h3>

                <ul>
                  <li>
                    <i class="fa fa-map-marker"></i>
                    Location:
                    <span>
                       PeaceJam Foundation <br>
                      1031 33rd Street, Suite 174, Denver CO 80205
                    </span>
                  </li>
                  <li>
                    <i class="fa fa-envelope"></i>
                    Email Us:
                    <a href="mailto:info@peacejam.org">
                      info@peacejam.org
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
  import api from "../services/api";
  const Loading = () => import("../components/Loading");
  // import MapHomeSection from "../components/MapHomeSection";
  import {required, email, maxLength} from "vuelidate/lib/validators";
  import useSWRV from "swrv";
  const LocationCards = () => import("../components/LocationCards");
  import Swal from "sweetalert2";

  export default {
    name: "Contact",
    components: {
      LocationCards,
      // MapHomeSection,
      Loading
    },
    data() {
      return {
        // Todo: insert available lists into db table, so we can dinamically retrieve them
        globalNewsletters: [
          { name: "Educators", id: "eada21f6-ec85-11ea-82a3-d4ae526edc76" },
          { name: "Community Partner", id: "e2426288-ec85-11ea-82a3-d4ae526edc76" },
          { name: "PeaceJam Youth", id: "e66ea75e-ec85-11ea-82a3-d4ae526edc76" },
          //{ name: "PeaceJam alumni", id: "e6c5f060-c319-11e7-9a00-d4ae52754b78" },
          { name: "PeaceJam Supporter", id: "29c1defc-4570-11eb-ac20-fa163eaf1c42" }
        ],
        billionActsNewsletter: {
          name: "Billion Acts Newsletter", id: "a6817330-8661-11e6-9707-d4ae526edc76"
        },
        peaceJamGeneralNewsletter: {
          name: "PeaceJam General Mailing List", id: "058c8f50-3d73-11e6-a333-d4ae52754b78"
        },
        isBillionActsNewsletterSelected: null,
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          location: "",
          message: "",
          listMemberships: []
        },
        newsletterError: false,
        submitted: false,

        constantContactClientId: "c9dc7ec5-4419-4d08-a2cb-8709dd67a328",
        constantClientSecret: "5NHH5wkBpSkm0rxZbJSPQg"
      }
    },
    setup() {
      let {
        data: locations_data,
        error: locations_error,
        isValidating: locations_isValidating
      } = useSWRV(() => `items/locations?status=published&fields=id,address,country,email,image,phone,status,title,country_name.*,type.*`, api.fetcher);

      return {
        locations_data,
        locations_error,
        locations_isValidating
      }
    },
    validations: {
      contact: {
        firstName: {required,  maxLength: maxLength(50)},
        lastName: {required,  maxLength: maxLength(50)},
        email: {required, email,  maxLength: maxLength(80)},
        location: {maxLength: maxLength(255)},
        message: {required, maxLength: maxLength(255)},
      }
    },
    methods: {
      simulateInlineConstantContactForm() {

        var constantContactInlineForm = document.getElementById("constant_contact_inline_form");
        var form = constantContactInlineForm.getElementsByTagName("form")[0];

        var emailInput = form.querySelectorAll('[name="email_address"]')[0];
        var firstNameInput = form.querySelectorAll('[name="first_name"]')[0];
        var lastNameInput = form.querySelectorAll('[name="last_name"]')[0];
        var locationInput = form.querySelectorAll('[name="custom_field_string_location_state_country"]')[0];
        var messageInput = form.querySelectorAll('[name="custom_field_string_your_message"]')[0];

        form.getElementsByTagName("input").forEach(element => {
          element.setAttribute("tempId", "cc" + element.getAttribute("value") );
        });

        this.contact.listMemberships.forEach(listId => {
          var checkbox = form.querySelectorAll("input[tempId=" + "cc" + listId + "]")[0];
          if (checkbox == null)
            return
          checkbox.click();
        });
        emailInput.value = this.contact.email;
        firstNameInput.value = this.contact.firstName;
        lastNameInput.value = this.contact.lastName;
        locationInput.value = this.contact.location;
        messageInput.value = this.contact.message;

        var constantContactFormButton = document.querySelectorAll('[data-qe-id="form-button"]')[0];
        constantContactFormButton.click();
      },
      createOrUpdateContact(){
        // send message implementation is pending here
        this.simulateInlineConstantContactForm();
        Swal.fire({
          icon: "success",
          title: "You have been successfully subscribed",
          showConfirmButton: true,
          timer: 3000,
        });
        this.resetContactFormData();
      },
      resetContactFormData() {
        this.contact = {
          firstName: "",
          lastName: "",
          email: "",
          location: "",
          message: "",
          listMemberships: []
        }
        this.$v.$reset();
        this.submitted = false;
      },
      handleSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        if (this.contact.listMemberships.length == 0)
        {
          this.newsletterError = true;
          return;
        }
        else {
          this.newsletterError = false;
        }

        if (this.isBillionActsNewsletterSelected) {
          this.contact.listMemberships.push(this.billionActsNewsletter.id)
        }
        this.createOrUpdateContact();
      }
    },
    async created() {
      let ctctcdn = document.createElement('script')
      ctctcdn.setAttribute('id', 'signupScript')
      ctctcdn.setAttribute('async', 'async')
      ctctcdn.setAttribute('defer', 'defer')
      ctctcdn.setAttribute('src', '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js')
      document.head.appendChild(ctctcdn)
    },
    async mounted() {
      window.scrollTo(0, 0);
      
      setTimeout(() => {
        window.ctctOnLoadCallback();
      }, 1000)
    }
  }
</script>

<style scoped lang="scss">
  .loading {
    height: 100%;
  }

  #mapHomeSection {
    background: #fdc540;
  }

  .form-group {
    position: relative;

    .with-errors {
      position: absolute;
      color: #dc3545;
      font-size: 13px;
    }
  }

  .newsletter {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: max-content;
    }
  }

  .bg-image {
    background-image: url("../assets/img/contatc-page-bg.jpeg");
    background-position: 60%;
    background-size: cover;
  }

  .contact-content {
    p {
      font-size: 18px;
    }
  }
</style>
